import Carousel from "react-material-ui-carousel";
import React, { useEffect, useState } from "react";
import {
  Grid,
  Typography,
  Button,
  CardActions,
  Card,
  CardContent,
} from "@mui/material";
import icon1 from "../imgs/cardIcon1.png";
import icon2 from "../imgs/cardIcon2.png";
import icon3 from "../imgs/cardIcon3.png";
import icon4 from "../imgs/cardIcon4.png";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "../i18n/config";

function Item(props: any) {
  const [cookies, setCookie] = useCookies(["lang"]);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  return (
    <Grid container p={3} pb={8}>
      {props.items.map((item: any) => (
        <Grid md={3} sm={6} xs={12}>
          <Card
            sx={{
              width: " 95%",
              borderRadius: "12px",
              height: "100%",
              flexDirection: "column",
              display: "flex",
              direction: cookies.lang == "ar" ? "rtl" : "",
            }}
          >
            <CardContent>
              <Typography p={0} m={0}>
                <img src={item.icon as any} />
              </Typography>
              <Typography
                sx={{
                  mb: 1.5,
                  color: "rgba(51, 51, 51, 1)",
                  fontSize: "18px",
                  fontWeight: "500",
                }}
              >
                {i18next.t(item.name)}
              </Typography>
              <Typography
                sx={{
                  color: "rgba(79, 79, 79, 1)",
                  fontSize: "14px",
                  fontWeight: "400",
                  lineHeight: "1.8",
                }}
              >
                {i18next.t(item.description)}
              </Typography>
            </CardContent>
            <CardActions sx={{ marginTop: "auto", marginBottom: "0px" }}>
              <Button size="small" sx={{ color: "rgba(221, 98, 58, 1)" }}>
                {i18next.t("watch_video")}
              </Button>
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

function ServicesCarousel() {
  const [cookies, setCookie] = useCookies(["lang"]);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);
  const [width, setwidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var items = [
    {
      name: "service_name1",
      description: "service_desc1",
      icon: icon4,
    },
    {
      name: "service_name3",
      description: "service_desc3",
      icon: icon2,
    },
    {
      name: "service_name4",
      description: "service_desc4",
      icon: icon3,
    },
    {
      name: "service_name2",
      description: "service_desc2",
      icon: icon1,
    },
  ];
  return (
    <Grid item xs={12}>
      {width > 900 ? (
        <Carousel
          navButtonsAlwaysVisible
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              backgroundColor: "rgba(72, 139, 137, 1)",
              borderRadius: "50%",
            },
          }}
          navButtonsWrapperProps={{
            // Move the buttons to the bottom. Unsetting top here to override default style.
            style: {
              bottom: "0px",
              top: "40%",
              left: "45%",
              right: "unset",
              position: "relative",
              // display: "inline",
              display: "none",
            },
          }}
          indicatorIconButtonProps={{
            style: {
              display: "none",
            },
          }}
          NextIcon={<ArrowLeftOutlinedIcon />}
          PrevIcon={<ArrowRightOutlinedIcon />}
        >
          {items.map((item, i) => (
            <Item key={i} items={items} />
          ))}
        </Carousel>
      ) : width > 600 ? (
        <Carousel
          navButtonsAlwaysVisible
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              backgroundColor: "rgba(72, 139, 137, 1)",
              borderRadius: "50%",
            },
          }}
          navButtonsWrapperProps={{
            // Move the buttons to the bottom. Unsetting top here to override default style.
            style: {
              bottom: "0px",
              top: "40%",
              left: "40%",
              right: "unset",
              position: "relative",
              display: "inline",
            },
          }}
          indicatorIconButtonProps={{
            style: {
              display: "none",
            },
          }}
          NextIcon={<ArrowLeftOutlinedIcon />}
          PrevIcon={<ArrowRightOutlinedIcon />}
        >
          <Item key={0} items={[items[0], items[1]]} />
          <Item key={1} items={[items[2], items[3]]} />
        </Carousel>
      ) : (
        <Carousel
          navButtonsAlwaysVisible
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              backgroundColor: "rgba(72, 139, 137, 1)",
              borderRadius: "50%",
            },
          }}
          navButtonsWrapperProps={{
            // Move the buttons to the bottom. Unsetting top here to override default style.
            style: {
              bottom: "0px",
              top: "40%",
              left: "30%",
              right: "unset",
              position: "relative",
              display: "inline",
            },
          }}
          indicatorIconButtonProps={{
            style: {
              display: "none",
            },
          }}
          NextIcon={<ArrowLeftOutlinedIcon />}
          PrevIcon={<ArrowRightOutlinedIcon />}
        >
          <Item key={0} items={[items[0]]} />
          <Item key={1} items={[items[1]]} />
          <Item key={2} items={[items[2]]} />
          <Item key={3} items={[items[3]]} />
        </Carousel>
      )}
    </Grid>
  );
}

export default ServicesCarousel;
