import React, { useEffect } from "react";
import { Grid, List, ListItem, Typography, CardHeader } from "@mui/material";
import logo from "../imgs/logo.png";
import facebook from "../imgs/facebook.png";
import youtube from "../imgs/youtube.png";
import instagram from "../imgs/instagram.png";
import googlePlay from "../imgs/googlePlay.png";
import appStore from "../imgs/appStore.png";
import flag from "../imgs/flag.png";
import twitter from "../imgs/twitter.png";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "../i18n/config";

function Footer() {
  const [cookies, setCookie] = useCookies(["lang"]);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  return (
    <Grid style={{ marginTop: "auto" }}>
      <Grid container className="white_container footer">
        <Grid item md={4.5} sm={6} xs={12}>
          <List sx={{ direction: cookies.lang == "ar" ? "rtl" : "" }}>
            <ListItem sx={{ p: 0 }}>
              <img src={logo} />
            </ListItem>
            <ListItem
              className="footer_item"
              sx={{
                textAlign: cookies.lang == "ar" ? "right" : "",
                lineHeight: "1.7",
              }}
            >
              {i18next.t("tadawi_is")}
            </ListItem>
            <ListItem>
              <img
                src={facebook}
                style={{ marginRight: cookies.lang == "ar" ? "0px" : "10px" }}
              />
              <img src={instagram} style={{ marginRight: "10px" }} />
              <img src={twitter} style={{ marginRight: "10px" }} />
              <img
                src={youtube}
                style={{ marginRight: cookies.lang == "ar" ? "10px" : "0px" }}
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item md={2.5} sm={6} xs={12}>
          <Typography
            className="footer_title"
            sx={{ textAlign: cookies.lang == "ar" ? "right" : "" }}
          >
            {i18next.t("important_links")}
          </Typography>
          <List sx={{ direction: cookies.lang == "ar" ? "rtl" : "" }}>
            <ListItem
              sx={{
                pl: cookies.lang == "ar" ? 1 : 0,
                pr: cookies.lang == "ar" ? 0 : 1,
              }}
              className="footer_item"
            >
              {i18next.t("joinus")}
            </ListItem>
            <ListItem
              sx={{
                pl: cookies.lang == "ar" ? 1 : 0,
                pr: cookies.lang == "ar" ? 0 : 1,
              }}
              className="footer_item"
            >
              {i18next.t("service_name3")}
            </ListItem>
            <ListItem
              sx={{
                pl: cookies.lang == "ar" ? 1 : 0,
                pr: cookies.lang == "ar" ? 0 : 1,
              }}
              className="footer_item"
            >
              {i18next.t("health_pack")}
            </ListItem>
            <ListItem
              sx={{
                pl: cookies.lang == "ar" ? 1 : 0,
                pr: cookies.lang == "ar" ? 0 : 1,
              }}
              className="footer_item"
            >
              {i18next.t("service_name1")}
            </ListItem>
            <ListItem
              sx={{
                pl: cookies.lang == "ar" ? 1 : 0,
                pr: cookies.lang == "ar" ? 0 : 1,
              }}
              className="footer_item"
            >
              {i18next.t("blog")}
            </ListItem>
          </List>
        </Grid>
        <Grid item md={2.5} sm={6} xs={12}>
          <Typography
            sx={{ textAlign: cookies.lang == "ar" ? "right" : "" }}
            className="footer_title"
          >
            {i18next.t("company")}
          </Typography>
          <List sx={{ direction: cookies.lang == "ar" ? "rtl" : "" }}>
            <ListItem
              sx={{
                pl: cookies.lang == "ar" ? 1 : 0,
                pr: cookies.lang == "ar" ? 0 : 1,
                textAlign: cookies.lang == "ar" ? "right" : "",
              }}
              className="footer_item"
            >
              {i18next.t("aboutus")}
            </ListItem>
            <ListItem
              sx={{
                pl: cookies.lang == "ar" ? 1 : 0,
                pr: cookies.lang == "ar" ? 0 : 1,
                textAlign: cookies.lang == "ar" ? "right" : "",
              }}
              className="footer_item"
            >
              {i18next.t("help")}
            </ListItem>
            <ListItem
              sx={{
                pl: cookies.lang == "ar" ? 1 : 0,
                pr: cookies.lang == "ar" ? 0 : 1,
                textAlign: cookies.lang == "ar" ? "right" : "",
              }}
              className="footer_item"
            >
              {i18next.t("terms_cond")}
            </ListItem>
            <ListItem
              sx={{
                pl: cookies.lang == "ar" ? 1 : 0,
                pr: cookies.lang == "ar" ? 0 : 1,
                textAlign: cookies.lang == "ar" ? "right" : "",
              }}
              className="footer_item"
            >
              {i18next.t("policy")}
            </ListItem>
          </List>
        </Grid>
        <Grid item md={2.5} sm={6} xs={12}>
          <Typography
            sx={{
              textAlign: cookies.lang == "ar" ? "right" : "",
              pr: cookies.lang == "ar" ? 2.5 : 1,
            }}
            className="footer_title"
          >
            {i18next.t("contact_us")}
          </Typography>
          <List sx={{ direction: cookies.lang == "ar" ? "rtl" : "" }}>
            <ListItem
              sx={{
                pl: cookies.lang == "ar" ? 1 : 0,
                pr: cookies.lang == "ar" ? 0 : 1,
              }}
              className="footer_item"
            >
              <CardHeader
                sx={{ p: 0 }}
                avatar={
                  <svg
                    width="25"
                    height="24"
                    viewBox="0 0 25 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    style={{ marginLeft: cookies.lang == "ar" ? "10px" : "" }}
                  >
                    <path
                      d="M17.126 20.5H7.12598C4.12598 20.5 2.12598 19 2.12598 15.5V8.5C2.12598 5 4.12598 3.5 7.12598 3.5H17.126C20.126 3.5 22.126 5 22.126 8.5V15.5C22.126 19 20.126 20.5 17.126 20.5Z"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M17.126 9L13.996 11.5C12.966 12.32 11.276 12.32 10.246 11.5L7.12598 9"
                      stroke="white"
                      stroke-width="1.5"
                      stroke-miterlimit="10"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                }
                title={"info@Tadawy.me"}
              />
            </ListItem>
            <ListItem
              sx={{
                pl: cookies.lang == "ar" ? 1 : 0,
                pr: cookies.lang == "ar" ? 0 : 1,
              }}
              className="footer_item"
            >
              <CardHeader
                sx={{ p: 0 }}
                avatar={<img src={flag} />}
                title={"Turkey"}
                subheader={
                  <span
                    style={{
                      fontSize: "12px",
                      color: "white",
                      marginRight: cookies.lang == "ar" ? "10px" : "",
                    }}
                  >
                    +55 525 566
                  </span>
                }
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
      <Grid
        p={1}
        sx={{
          backgroundColor: "rgba(21, 21, 21, 1)",
          color: "white",
          textAlign: "center",
        }}
      >
        <Typography variant="caption">{i18next.t("right_reserv")}</Typography>
      </Grid>
    </Grid>
  );
}

export default Footer;
