import React, { useState, useEffect } from "react";
import {
  Box,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  Container,
  Button,
  MenuItem,
  AppBar,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import logo from "../imgs/logo.png";
// import Login from "../views/Register/Login";
import { useNavigate, useLocation } from "react-router-dom";
// import ServicesSubMenu from "./ServicesSubMenu";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "../i18n/config";
import GTranslateIcon from "@mui/icons-material/GTranslate";
import translate from "../imgs/translate.png";

const pages = ["home", "aboutus", "joinus", "fqa"];
function AppContainer() {
  const [cookies, setCookie] = useCookies(["lang"]);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);
  const setLanguage = (lang) => {
    setCookie("lang", lang, { path: "/" });
  };

  const changeLangToEn = () => {
    setLanguage("en");
    i18n.changeLanguage("en");
  };

  const changeLangToAr = () => {
    setLanguage("ar");
    i18n.changeLanguage("ar");
  };
  const [anchorElNav, setAnchorElNav] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
    null
  );
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const handleOpenNavMenu = (event: any) => {
    setAnchorElNav(event.currentTarget);
  };
  // const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
  //   setAnchorElUser(event.currentTarget);
  // };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenServicesMenu = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseServicesMenu = () => {
    setAnchorEl(null);
  };

  return (
    <AppBar position="static" sx={{ background: "white", p: 4 }} elevation={0}>
      {/* <ServicesSubMenu
        open={Boolean(anchorEl)}
        onClose={handleCloseServicesMenu}
        anchorEl={anchorEl}
      /> */}

      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            noWrap
            component="a"
            href=""
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <img src={logo} style={{ width: "200px" }} />
          </Typography>

          <Box sx={{ flexGrow: 1, display: { xs: "flex", md: "none" } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit"
            >
              <MenuIcon sx={{ color: "#488B89" }} />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "left",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: "block", md: "none" },
              }}
            >
              {pages.map((page) => (
                <MenuItem
                  sx={{ direction: cookies.lang == "ar" ? "rtl" : "" }}
                  key={page}
                  onClick={handleOpenServicesMenu}
                >
                  <Typography textAlign="center"> {i18next.t(page)}</Typography>
                </MenuItem>
              ))}
              <MenuItem sx={{ direction: cookies.lang == "ar" ? "rtl" : "" }}>
                <IconButton
                  onClick={
                    cookies.lang == "ar" ? changeLangToEn : changeLangToAr
                  }
                >
                  {/* <GTranslateIcon sx={{ color: "#488B89" }} /> */}
                  <img
                    src={translate}
                    style={{ width: "40px", height: "40px" }}
                  />
                </IconButton>
              </MenuItem>
              {/* <Button
                sx={{ pr: 2, pl: 2, color: "#488B89" }}
                className="no_borderButton"
                onClick={() =>
                  navigate("/signin", {
                    state: {},
                  })
                }
              >
                {i18next.t("signin")}
              </Button> */}
              <Button
                sx={{ pr: 2, pl: 2, ml: 1 }}
                className="main_button"
                onClick={() => setOpen(true)}
              >
                {i18next.t("start")}
              </Button>
            </Menu>
          </Box>
          <Typography
            noWrap
            component="a"
            href=""
            sx={{
              display: { xs: "flex", md: "none" },
            }}
          >
            <img src={logo} style={{ width: "200px" }} />
          </Typography>
          <Box
            sx={{
              flexGrow: 1,
              justifyContent: "center",
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              <Button
                key={page}
                onClick={handleOpenServicesMenu}
                sx={{
                  my: 2,
                  color: "#333333",
                  display: "block",
                  pr: 3,
                  pl: 3,
                }}
                className="navButton"
              >
                {i18next.t(page)}
              </Button>
            ))}
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            <IconButton
              onClick={cookies.lang == "ar" ? changeLangToEn : changeLangToAr}
              sx={{ marginRight: "20px" }}
            >
              {/* <GTranslateIcon sx={{ color: "#488B89" }} /> */}
              <img
                src={translate}
                style={{ width: "40px", height: "40px" }}
                title={i18next.t("translate")}
              />
            </IconButton>
          </Box>
          <Box sx={{ flexGrow: 0, display: { xs: "none", md: "flex" } }}>
            {/* <Button
              sx={{ pr: 4, pl: 4, color: "#488B89" }}
              className="no_borderButton"
              onClick={() =>
                navigate("/signin", {
                  state: {},
                })
              }
            >
              {i18next.t("signin")}
            </Button> */}
            <Button
              sx={{ pr: 4, pl: 4 }}
              className="main_button"
              onClick={() => setOpen(true)}
            >
              {i18next.t("start")}
            </Button>
          </Box>
        </Toolbar>
      </Container>
      {/* <Login open={open} onClose={() => setOpen(false)} /> */}
    </AppBar>
  );
}
export default AppContainer;
