import Carousel from "react-material-ui-carousel";
import React, { useEffect, useState } from "react";
import {
  Grid,
  CardActions,
  Card,
  CardHeader,
  CardContent,
} from "@mui/material";
import video1 from "../imgs/video1.png";
import video2 from "../imgs/video2.png";
import ArrowRightOutlinedIcon from "@mui/icons-material/ArrowRightOutlined";
import ArrowLeftOutlinedIcon from "@mui/icons-material/ArrowLeftOutlined";
import play from "../imgs/play.png";
import user from "../imgs/defaultImg.png";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "../i18n/config";

function Item(props: any) {
  const [cookies, setCookie] = useCookies(["lang"]);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);

  return (
    <Grid
      container
      p={3}
      pt={10}
      pr={5}
      direction="row"
      justifyContent="space-around"
      alignItems="center"
    >
      {props.items.map((item: any) => (
        <Grid
          md={3.2}
          sm={5.5}
          xs={11}
          sx={{
            m: { md: 1, sm: 0.5, xs: 0 },
            // width: { lg: "380px" },
          }}
          style={{ position: "relative" }}
        >
          {/* <img
            src={item.photo}
            style={{
              
            }}
          /> */}

          <Card
            className="client_card"
            elevation={0}
            onClick={(ev) => {
              // var symbol =
              // (document as any).getElementById(item.id).play()
              //modify source to autoplay and start video
              (document as any).getElementById(item.id).src +=
                (document as any).getElementById(item.id).src + "&autoplay=1";
              // ev.preventDefault();
            }}
          >
            <CardHeader
              className="opacity"
              avatar={
                <img
                  src={user}
                  style={{ borderRadius: "50%", width: "35px" }}
                />
              }
              title={
                <span
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "white",
                  }}
                >
                  {item.name}
                </span>
              }
              action={
                <p
                  style={{
                    fontSize: "12px",
                    fontWeight: "400",
                    color: "white",
                    padding: "0px",
                  }}
                >
                  {item.date}
                </p>
              }
            />
            <CardContent sx={{ p: 0, mt: -9 }}>
              <iframe
                id={item.id}
                style={{
                  width: "100%",
                  borderRadius: "12px",
                  height: "280px",
                  border: "0px solid white",
                }}
                src={item.photo}
              ></iframe>
            </CardContent>
            <CardActions
              sx={{ marginTop: "auto", marginBottom: "0px", padding: "20px" }}
            >
              <img src={play} />
            </CardActions>
          </Card>
        </Grid>
      ))}
    </Grid>
  );
}

function ClientsCarousel() {
  const [width, setwidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setwidth(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  var items = [
    {
      id: "clientvideo1",
      name: "Eman Elsherbiny",
      date: "2022/03/17",
      photo: "https://www.youtube.com/embed/6qUwdYq1_wE",
    },
    {
      id: "clientvideo2",
      name: "Eman Elsherbiny",
      date: "2022/03/17",
      photo: "https://www.youtube.com/embed/D4j-FZRUyCw",
    },
    {
      id: "clientvideo3",

      name: "Eman Elsherbiny",
      date: "2022/03/17",
      photo: "https://www.youtube.com/embed/k6LVX8UgBm0",
    },
    {
      id: "clientvideo4",

      name: "Eman Elsherbiny",
      date: "2022/03/17",
      photo: "https://www.youtube.com/embed/zeb8NWwnIGQ",
    },
    {
      id: "clientvideo5",

      name: "Eman Elsherbiny",
      date: "2022/03/17",
      photo: "https://www.youtube.com/embed/JBOgPVtX4bs",
    },
    {
      id: "clientvideo6",

      name: "Eman Elsherbiny",
      date: "2022/03/17",
      photo: "https://www.youtube.com/embed/Uz8R0ChPprQ",
    },
    {
      id: "clientvideo7",

      name: "Eman Elsherbiny",
      date: "2022/03/17",
      photo: "https://www.youtube.com/embed/OJUb0rJxLlk",
    },
    {
      id: "clientvideo8",

      name: "Eman Elsherbiny",
      date: "2022/03/17",
      photo: "https://www.youtube.com/embed/wU5mwkbr-mU",
    },
  ];
  return (
    <Grid item xs={12} p={5} className="clients">
      {width > 900 ? (
        <Carousel
          navButtonsAlwaysVisible
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              backgroundColor: "rgba(72, 139, 137, 1)",
              borderRadius: "50%",
            },
          }}
          navButtonsWrapperProps={{
            // Move the buttons to the bottom. Unsetting top here to override default style.
            style: {
              top: "-370px",
              left: "4%",
              right: "unset",
              position: "relative",
              display: "inline",
            },
          }}
          indicatorIconButtonProps={{
            style: {
              display: "none",
            },
          }}
          NextIcon={<ArrowLeftOutlinedIcon />}
          PrevIcon={<ArrowRightOutlinedIcon />}
        >
          {/* {items.map((item, i) => (
            <Item key={i} items={items} />
          ))} */}
          <Item key={0} items={[items[0], items[1], items[2]]} />
          <Item key={1} items={[items[3], items[4], items[5]]} />
          <Item key={0} items={[items[6], items[7]]} />
        </Carousel>
      ) : width > 600 ? (
        <Carousel
          navButtonsAlwaysVisible
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              backgroundColor: "rgba(72, 139, 137, 1)",
              borderRadius: "50%",
            },
          }}
          navButtonsWrapperProps={{
            // Move the buttons to the bottom. Unsetting top here to override default style.
            style: {
              top: "-370px",
              left: "4%",
              right: "unset",
              position: "relative",
              display: "inline",
            },
          }}
          indicatorIconButtonProps={{
            style: {
              display: "none",
            },
          }}
          NextIcon={<ArrowLeftOutlinedIcon />}
          PrevIcon={<ArrowRightOutlinedIcon />}
        >
          <Item key={0} items={[items[0], items[1]]} />
          <Item key={1} items={[items[2], items[3]]} />
          <Item key={0} items={[items[4], items[5]]} />
          <Item key={1} items={[items[6], items[7]]} />
        </Carousel>
      ) : (
        <Carousel
          navButtonsAlwaysVisible
          navButtonsProps={{
            // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
            style: {
              backgroundColor: "rgba(72, 139, 137, 1)",
              borderRadius: "50%",
            },
          }}
          navButtonsWrapperProps={{
            // Move the buttons to the bottom. Unsetting top here to override default style.
            style: {
              top: "-370px",
              left: "5%",
              right: "unset",
              position: "relative",
              display: "inline",
            },
          }}
          indicatorIconButtonProps={{
            style: {
              display: "none",
            },
          }}
          NextIcon={<ArrowLeftOutlinedIcon />}
          PrevIcon={<ArrowRightOutlinedIcon />}
        >
          {items.map((item, i) => (
            <Item key={i} items={[items[i]]} />
          ))}
        </Carousel>
      )}
    </Grid>
  );
}

export default ClientsCarousel;
