import React, { useEffect } from "react";
import NavBar from "../usedComponents/NavBar";
import Footer from "../usedComponents/Footer";
import {
  Grid,
  Typography,
  ListItem,
  List,
  ListItemIcon,
  FormControl,
  OutlinedInput,
  InputAdornment,
  Button,
  CardHeader,
} from "@mui/material";
import phones from "../imgs/twoPhones.png";
import googlePlay from "../imgs/googlePlay.png";
import appStore from "../imgs/appStore.png";
import phone from "../imgs/phone.png";
import tick from "../imgs/tick-circle.png";
import logo from "../imgs/logo.png";
import ServicesCarousel from "./ServicesCarousel";
import ClientsCarousel from "./ClientsCarousel";
import send from "../imgs/send.png";
import input from "../imgs/input.png";
import phones3 from "../imgs/phones3.png";
import phones33 from "../imgs/phones33.png";
import video1 from "../imgs/video3.png";
import video2 from "../imgs/video4.png";
import video3 from "../imgs/video5.png";
import video4 from "../imgs/video6.png";
import play from "../imgs/play.png";
import { useCookies } from "react-cookie";
import { useTranslation } from "react-i18next";
import i18next from "../i18n/config";

const items = [
  { name: "doctor", num: "+ 50" },
  { name: "user", num: "+ 16000" },
  { name: "user", num: "+ 16000" },
  { name: "online_consult", num: "+ 6000" },
  { name: "provider", num: "+ 150" },
];

function Item() {
  return (
    <Grid container pt={14} pb={12}>
      {items.map((item: any) => (
        <Grid item md={2.4} sm={6} xs={12} style={{ textAlign: "center" }}>
          <CardHeader
            title={
              <span
                style={{
                  fontSize: "32px",
                  fontWeight: "400",
                  color: "white",
                }}
              >
                {item.num}
              </span>
            }
            subheader={
              <span
                style={{
                  fontSize: "16px",
                  fontWeight: "300",
                  color: "white",
                }}
              >
                {i18next.t(item.name)}
              </span>
            }
          />
        </Grid>
      ))}
    </Grid>
  );
}

function AppContainer() {
  const [cookies, setCookie] = useCookies(["lang"]);
  const { i18n } = useTranslation();

  useEffect(() => {
    if (cookies && cookies.lang) {
      i18n.changeLanguage(cookies.lang);
    } else {
      i18n.changeLanguage("en");
    }
  }, []);
  return (
    <Grid
      container
      sx={{
        width: "100%",
        minHeight: "100vh",
        flexDirection: "column",
        display: "flex",
      }}
    >
      <NavBar />
      <Grid container p={5}>
        <Grid
          item
          md={7}
          sm={12}
          p={3}
          sx={{ pt: { md: 12, sm: 1, xs: 1 } }}
          className="head_back"
        >
          <Typography
            sx={{
              fontWeight: "600",
              color: "rgba(51, 51, 51, 1)",
              textAlign: cookies.lang == "ar" ? "right" : "left",
            }}
            className="head"
          >
            {i18next.t("your_header")}
            <span style={{ color: "rgba(72, 139, 137, 1)" }}>
              {i18next.t("health_header")}
            </span>
            {i18next.t("respon_header")}
          </Typography>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              color: "rgba(79, 79, 79, 1)",
              textAlign: cookies.lang == "ar" ? "right" : "left",
              lineHeight: "1.7",
              mt: 3,
            }}
          >
            {i18next.t("tadawi_is")}
          </Typography>
          <Typography sx={{ pt: 5 }}>
            <img
              src={googlePlay}
              style={{ width: "30%", marginRight: "6px" }}
            />
            <img src={appStore} style={{ width: "30%" }} />
            <svg
              style={{ paddingLeft: "20px" }}
              width="12%"
              height="38"
              viewBox="0 0 70 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M4.13224 9.55328C4.85001 10.1999 5.86173 10.8303 6.34259 11.2266C7.47093 12.1614 8.63047 13.0586 9.77721 13.9686C11.168 15.0763 9.54361 17.2752 8.12675 16.1897C6.43463 14.8926 4.81612 13.4964 3.23292 12.0656C2.2588 11.1862 0.463913 10.0403 0.432898 8.54285C0.40201 7.14869 1.89049 6.07258 2.77955 5.2297C4.36379 3.72292 6.046 2.3387 7.7995 1.04392C8.67599 0.394721 9.65724 1.65684 8.84536 2.3743C7.88103 3.22794 6.93465 4.10483 6.03493 5.02673C5.38073 5.69584 4.74806 6.38771 4.1359 7.09509C4.06993 7.17094 4.00344 7.24317 3.93643 7.31178C16.7577 2.71656 28.5751 12.3723 40.8114 14.7687C44.2276 15.4358 47.9075 15.6187 51.2159 14.3786C51.3823 14.3142 51.5476 14.2426 51.7129 14.171C49.5817 12.7189 47.8436 10.6387 47.1706 8.10369C46.4966 5.56147 47.1475 2.56433 49.7252 1.40646C52.1831 0.306282 55.4024 1.23397 56.9994 3.40081C58.7912 5.83134 58.4098 9.28079 56.9414 11.7526C56.3368 12.7689 55.6044 13.6078 54.7807 14.2938C59.4687 16.3365 65.2415 15.5009 69.0721 12.0274C69.3601 11.7649 69.8426 12.2237 69.5695 12.5136C65.512 16.8313 58.7913 17.7137 53.5189 15.1899C48.4018 18.2604 40.6987 16.8734 35.4504 15.2097C25.4687 12.0558 14.7628 5.13811 4.13224 9.55328ZM53.086 13.414C55.4701 11.8225 57.1574 9.11648 56.5243 6.11821C55.8294 2.81511 50.8047 0.923844 48.901 4.24015C47.1967 7.21063 49.8372 10.9739 52.1352 12.749C52.4438 12.9891 52.7606 13.2096 53.086 13.414Z"
                fill="#DD623A"
              />
            </svg>
          </Typography>
        </Grid>
        <Grid item md={5} sm={12} sx={{ textAlign: "center" }}>
          <img src={phones as any} className="sec_img1" />
        </Grid>
      </Grid>

      <Grid container p={5}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              color: "rgba(221, 98, 58, 1)",
            }}
          >
            {i18next.t("tadawi_app")}

            <svg
              width="25"
              height="30"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.36016 4.39144C4.90815 8.35425 3.91164 12.4297 2.64298 16.4423"
                stroke="#488B89"
                stroke-width="2"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.362 1.01608C14.6627 6.79566 10.5233 13.1752 6.75804 19.5842"
                stroke="#488B89"
                stroke-width="2"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.611 15.228C18.8196 16.8198 14.2156 20.6855 10.7991 23.2194"
                stroke="#488B89"
                stroke-width="2"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "32px",
              color: "rgba(41, 49, 60, 1)",
            }}
          >
            {i18next.t("tadawi_about")}
          </Typography>
        </Grid>
        <Grid item md={4.5} sm={12} p={3} sx={{ textAlign: "center" }}>
          <img src={phone} className="sec_img2" />
        </Grid>
        <Grid item md={7.5} sm={12} p={3} pt={8}>
          <List
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              pt: 2,
              color: "#4F4F4F",
              direction: cookies.lang == "ar" ? "rtl" : "",
              lineHeight: "1.8",
            }}
          >
            <ListItem sx={{ p: 0, pb: 2 }}>
              <img src={logo} />
            </ListItem>
            <ListItem
              sx={{ textAlign: cookies.lang == "ar" ? "right" : "left" }}
            >
              <ListItemIcon sx={{ minWidth: "20px" }}>
                <img style={{ width: "15px" }} src={tick} />
              </ListItemIcon>
              <span>{i18next.t("about_list1")}</span>
            </ListItem>
            <ListItem
              sx={{ textAlign: cookies.lang == "ar" ? "right" : "left" }}
            >
              <ListItemIcon sx={{ minWidth: "20px" }}>
                <img style={{ width: "15px" }} src={tick} />
              </ListItemIcon>
              <span>{i18next.t("about_list2")}</span>
            </ListItem>
            <ListItem
              sx={{ textAlign: cookies.lang == "ar" ? "right" : "left" }}
            >
              <ListItemIcon sx={{ minWidth: "20px" }}>
                <img style={{ width: "15px" }} src={tick} />
              </ListItemIcon>
              <span>{i18next.t("about_list3")}</span>
            </ListItem>
            <ListItem
              sx={{ textAlign: cookies.lang == "ar" ? "right" : "left" }}
            >
              <ListItemIcon sx={{ minWidth: "20px" }}>
                <img style={{ width: "15px" }} src={tick} />
              </ListItemIcon>
              <span>{i18next.t("about_list4")}</span>
            </ListItem>
            <ListItem
              sx={{ textAlign: cookies.lang == "ar" ? "right" : "left" }}
            >
              <ListItemIcon sx={{ minWidth: "20px" }}>
                <img style={{ width: "15px" }} src={tick} />
              </ListItemIcon>
              <span>{i18next.t("about_list5")}</span>
            </ListItem>
          </List>
        </Grid>
      </Grid>

      <Grid container p={5}>
        <Grid item md={6} sm={12} xs={12} sx={{ textAlign: "center" }}>
          {/* <img src={video2}  /> */}
          <iframe
            className="sec_img3"
            style={{ borderRadius: "45px", border: "0px solid white" }}
            src="https://www.youtube.com/embed/Sg-kspXnfqc"
          ></iframe>
        </Grid>
        <Grid
          container
          md={6}
          sm={12}
          xs={12}
          p={1}
          sx={{ textAlign: "center" }}
          direction="row"
          justifyContent="center"
          alignItems="center"
        >
          <Grid item p={0.5} sx={{ position: "relative" }}>
            {/* <img src={video3} className="sec_img4" /> */}
            {/* <img
              src={play}
              style={{ position: "absolute", top: "40%", left: "45%" }}
            /> */}
            <iframe
              className="sec_img4"
              style={{ borderRadius: "12px", border: "0px solid white" }}
              src="https://www.youtube.com/embed/C6zU9gvnpfs"
            ></iframe>
          </Grid>
          <Grid item p={0.5} sx={{ position: "relative" }}>
            <iframe
              className="sec_img4"
              style={{ borderRadius: "12px", border: "0px solid white" }}
              src="https://www.youtube.com/embed/MYesAP29yiM"
            ></iframe>
          </Grid>
          <Grid item p={0.5} sx={{ position: "relative", textAlign: "center" }}>
            <iframe
              className="sec_img4"
              style={{ borderRadius: "12px", border: "0px solid white" }}
              src="https://www.youtube.com/embed/MSry1SxLHlU"
            ></iframe>
          </Grid>
          <Grid item p={0.5} sx={{ position: "relative", textAlign: "center" }}>
            <iframe
              className="sec_img4"
              style={{ borderRadius: "12px", border: "0px solid white" }}
              src="https://www.youtube.com/embed/VIXVOHu_zpo"
            ></iframe>
          </Grid>
        </Grid>
      </Grid>

      <Grid container p={5} sx={{ backgroundColor: "rgba(249, 243, 235, 1)" }}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              color: "rgba(221, 98, 58, 1)",
            }}
          >
            {i18next.t("our_service")}

            <svg
              width="25"
              height="30"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.36016 4.39144C4.90815 8.35425 3.91164 12.4297 2.64298 16.4423"
                stroke="#488B89"
                stroke-width="2"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.362 1.01608C14.6627 6.79566 10.5233 13.1752 6.75804 19.5842"
                stroke="#488B89"
                stroke-width="2"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.611 15.228C18.8196 16.8198 14.2156 20.6855 10.7991 23.2194"
                stroke="#488B89"
                stroke-width="2"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "32px",
              color: "rgba(41, 49, 60, 1)",
            }}
          >
            {i18next.t("health_service")}
          </Typography>
        </Grid>
        <ServicesCarousel />
      </Grid>

      <Grid container p={5}>
        <Grid item xs={12} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              color: "rgba(221, 98, 58, 1)",
            }}
          >
            {i18next.t("book_appoint")}

            <svg
              width="25"
              height="30"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.36016 4.39144C4.90815 8.35425 3.91164 12.4297 2.64298 16.4423"
                stroke="#488B89"
                stroke-width="2"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.362 1.01608C14.6627 6.79566 10.5233 13.1752 6.75804 19.5842"
                stroke="#488B89"
                stroke-width="2"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.611 15.228C18.8196 16.8198 14.2156 20.6855 10.7991 23.2194"
                stroke="#488B89"
                stroke-width="2"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "32px",
              color: "rgba(41, 49, 60, 1)",
            }}
          >
            {i18next.t("book_cons")}
          </Typography>
        </Grid>

        <Grid
          item
          md={5}
          sm={12}
          xs={12}
          p={3}
          sx={{ pt: { lg: 4, md: 1, xs: 1 } }}
        >
          <List
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              pt: 2,
              color: "#4F4F4F",
              direction: cookies.lang == "ar" ? "rtl" : "",
            }}
          >
            <ListItem
              sx={{
                p: 2,
                fontSize: "22px",
                fontWeight: "500",
                color: "rgba(51, 51, 51, 1)",
                textAlign: cookies.lang == "ar" ? "right" : "",
              }}
            >
              {i18next.t("book_locally")}
            </ListItem>
            <ListItem sx={{ textAlign: cookies.lang == "ar" ? "right" : "" }}>
              <ListItemIcon sx={{ minWidth: "20px" }}>
                <img style={{ width: "15px" }} src={tick} />
              </ListItemIcon>
              <span>{i18next.t("book_list1")}</span>
            </ListItem>
            <ListItem sx={{ textAlign: cookies.lang == "ar" ? "right" : "" }}>
              <ListItemIcon sx={{ minWidth: "20px" }}>
                <img style={{ width: "15px" }} src={tick} />
              </ListItemIcon>
              <span>{i18next.t("book_list2")}</span>
            </ListItem>
            <ListItem
              sx={{
                p: 2,
              }}
            >
              <Button sx={{ p: 3, mr: 2 }} className="orange_button">
                {i18next.t("book_now")}
              </Button>
            </ListItem>
          </List>
        </Grid>
        <Grid item md={7} sm={12} xs={12} sx={{ textAlign: "center" }}>
          <img src={phones3} className="sec_img6" />
        </Grid>
      </Grid>

      <Grid className="rectBack">
        <Grid p={5} className="maskBack">
          <Item />
        </Grid>
      </Grid>

      <Grid container p={5}>
        <Grid item md={7} sm={12} xs={12} sx={{ textAlign: "center" }}>
          <img
            src={phones33}
            className="sec_img7"
            // style={{ width: "97%", height: "600px" }}
          />
        </Grid>
        <Grid item md={5} sm={12} p={3} sx={{ pt: { md: 24, sm: 4 } }}>
          <Typography
            sx={{
              fontWeight: "500",
              fontSize: "22px",
              color: "rgba(51, 51, 51, 1)",
              textAlign: cookies.lang == "ar" ? "right" : "",
            }}
          >
            {i18next.t("easier")}
            <br></br>
            <span style={{ color: "rgba(221, 98, 58, 1)" }}>
              {i18next.t("application")}
            </span>{" "}
          </Typography>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "14px",
              color: "rgba(79, 79, 79, 1)",
              pt: 2,
              textAlign: cookies.lang == "ar" ? "right" : "",
            }}
          >
            {i18next.t("it_more")}
          </Typography>
          <Typography
            sx={{ pt: 5, textAlign: cookies.lang == "ar" ? "right" : "" }}
          >
            <img
              src={googlePlay}
              style={{ width: "30%", marginRight: "6px" }}
            />
            <img src={appStore} style={{ width: "30%" }} />
          </Typography>
        </Grid>
      </Grid>

      <Grid container p={0}>
        <Grid item p={5} xs={12} sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontWeight: "400",
              fontSize: "16px",
              color: "rgba(221, 98, 58, 1)",
            }}
          >
            {i18next.t("testimonial")}

            <svg
              width="25"
              height="30"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M6.36016 4.39144C4.90815 8.35425 3.91164 12.4297 2.64298 16.4423"
                stroke="#488B89"
                stroke-width="2"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M19.362 1.01608C14.6627 6.79566 10.5233 13.1752 6.75804 19.5842"
                stroke="#488B89"
                stroke-width="2"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M22.611 15.228C18.8196 16.8198 14.2156 20.6855 10.7991 23.2194"
                stroke="#488B89"
                stroke-width="2"
                stroke-miterlimit="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </Typography>
          <Typography
            sx={{
              fontWeight: "600",
              fontSize: "32px",
              color: "rgba(41, 49, 60, 1)",
            }}
          >
            {i18next.t("what_trusted")}{" "}
            <span style={{ color: "rgba(221, 98, 58, 1)" }}>
              {i18next.t("client")}
            </span>{" "}
            {i18next.t("say_tadawi")}
          </Typography>
        </Grid>
        <ClientsCarousel />
      </Grid>
      {/* 
      <Grid
        container
        sx={{
          pr: { lg: 25, md: 10, sm: 5, xs: 1 },
          pl: { lg: 25, md: 10, sm: 5, xs: 1 },
          mt: { md: 5, sm: 0, xs: 0 },
        }}
        pt={5}
        pb={5}
      >
        <Grid container p={0} className="orange_container">
          <Grid item md={6} sm={12} p={5}>
            <Typography
              sx={{
                fontWeight: "500",
                fontSize: "24px",
                color: "white",
                textAlign: cookies.lang == "ar" ? "right" : "",
              }}
            >
              {i18next.t("subscribe_news")}
            </Typography>
            <Typography
              sx={{
                fontWeight: "400",
                fontSize: "14px",
                color: "white",
                pt: 2,
                pr: cookies.lang == "ar" ? 0 : 4,
                pl: cookies.lang == "ar" ? 4 : 0,
                textAlign: cookies.lang == "ar" ? "right" : "",
              }}
            >
              {i18next.t("reciev_news")}{" "}
              <FormControl
                sx={{
                  mt: 3,
                  width: "95%",
                  backgroundColor: "white",
                  borderRadius: "12px",
                }}
                variant="outlined"
              >
                <OutlinedInput
                  dir={cookies.lang == "ar" ? "rtl" : ""}
                  placeholder={i18next.t("enter_email")}
                  id="outlined-adornment-weight"
                  endAdornment={
                    <InputAdornment position="end">
                      <img src={input} />
                    </InputAdornment>
                  }
                  aria-describedby="outlined-weight-helper-text"
                  inputProps={{
                    "aria-label": i18next.t("enter_email"),
                  }}
                />
              </FormControl>
            </Typography>
          </Grid>
          <Grid
            item
            md={6}
            p={0}
            sm={12}
            xs={12}
            sx={{
              mt: { xs: "120px", sm: "120px", md: "0px" },
              textAlign: "center",
            }}
          >
            <img
              src={send}
              style={{
                marginBottom: "-5px",
                marginTop: "-120px",
              }}
              className="sec_img8"
            />
          </Grid>
        </Grid>
      </Grid> */}

      <Footer />
    </Grid>
  );
}

export default AppContainer;
